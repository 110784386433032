import {
  InfoCircleOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import {
  Interval,
  addDays,
  eachDayOfInterval,
  format,
  isWithinInterval,
} from 'date-fns';
import { useState } from 'react';

import DoubleThunderbolt from 'app/components/commons/DoubleThunderbolt/DoubleThunderbolt';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { useAppSelector } from 'app/redux/hooks';
import type {
  OpeningForm,
  RoomOpening,
} from 'app/redux/models/RoomOpening/RoomOpening';
import {
  selectHotelAdminDirtOpenings,
  selectHotelAdminInventory,
  selectHotelAdminIsEditing,
} from 'app/redux/selectors/hotelAdmin';
import { RateModes } from 'app/utils/channelManagers/rateModes';
import { isEndOfWeek, isInDateArray, isPassed } from 'app/utils/dates';

import { StaycationDiscountTooltip } from '../../Tooltip/StaycationDiscountTooltip';
import { DuplicationModeData, Inventory, OpeningErrors } from '../../Types';
import {
  MAXIMUM_DISCOUNT,
  MINIMAL_DISCOUNT_DAY_PACKAGE,
  MINIMAL_DISCOUNT_STAYCATION,
  computeDiscount,
  computePackageValue,
  getDiscountLabel,
} from '../../Utils';
import { PricingRule } from '../AutomatedDiscount/PricingRule';
import { DuplicationMenu } from '../DuplicationMenu/DuplicationMenu';

import './DiscountRateRow.scss';

type Props = {
  room: RoomOpening;
  currentRange: Interval;
  handlePropertyChange: (
    dirtyOpening: OpeningForm | undefined,
    property: string,
    value: number
  ) => void;
  handleDuplicate: (
    property: 'bar' | 'stock' | 'discountPrice' | 'discountRate',
    value: number,
    day: Date,
    mode: DuplicationModeData,
    customDay?: Date
  ) => void;
  handleDuplicateHover: (
    day: Date,
    mode: DuplicationModeData,
    customDay?: Date
  ) => void;
  dropDuplicationHover: () => void;
  onFocus: (date: Date, roomId: number) => void;
  onBlur: () => void;
  discountRoomFocus: { date: Date; roomId: number } | undefined;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  lastMinuteAutoDiscountDays?: number;
  hasChannelManager: boolean;
  stockScrapperEnabled: boolean;
  urlTriggerPricingRule: boolean;
};

export const DiscountRateRow = ({
  room,
  currentRange,
  handlePropertyChange,
  handleDuplicate,
  handleDuplicateHover,
  dropDuplicationHover,
  onFocus,
  onBlur,
  discountRoomFocus,
  autoDiscountRate,
  lastMinuteAutoDiscountRate,
  lastMinuteAutoDiscountDays,
  hasChannelManager,
  stockScrapperEnabled,
  urlTriggerPricingRule,
}: Props) => {
  const isEditing = useAppSelector(selectHotelAdminIsEditing);
  const inventory = useAppSelector(selectHotelAdminInventory);
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);
  const { track } = useAmplitude();

  const [isPricingRuleOpen, setIsPricingRuleOpen] = useState(
    urlTriggerPricingRule
  );

  return (
    <Row>
      <Col
        className={[
          'extranet-inventory__property-cell extranet-inventory__head extranet-inventory__head--large',
          (hasChannelManager || stockScrapperEnabled) &&
            'extranet-inventory-automated-discount',
        ].join(' ')}
      >
        {hasChannelManager ? (
          <>
            <div className="extranet-inventory__head--align-content">
              Discount
              <Tooltip
                title={<StaycationDiscountTooltip />}
                overlayClassName="extranet-inventory-tooltip"
                placement="topLeft"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <Button
              id="toggleAutomatedDiscountButton"
              key="automated-discount-button"
              className="automated-discount__toggle-modal-button"
              type="link"
              disabled={isEditing}
              onClick={() => {
                autoDiscountRate
                  ? track('Click Pricing rule activated')
                  : track('Click Activate pricing rule');
                setIsPricingRuleOpen(true);
              }}
            >
              {autoDiscountRate || lastMinuteAutoDiscountRate
                ? [
                    <ThunderboltFilled disabled={isEditing} />,
                    'Default discount activated',
                  ]
                : [
                    <ThunderboltOutlined disabled={isEditing} />,
                    'Activate default discount',
                  ]}
            </Button>
            {inventory && (
              <PricingRule
                onClose={() => setIsPricingRuleOpen(false)}
                inventory={inventory}
                hotelId={room.room.hotelId}
                autoDiscountRate={autoDiscountRate}
                lastMinuteAutoDiscountRate={lastMinuteAutoDiscountRate}
                lastMinuteAutoDiscountDays={lastMinuteAutoDiscountDays}
                startCurrentRange={currentRange.start}
                isOpen={isPricingRuleOpen}
              />
            )}
          </>
        ) : stockScrapperEnabled ? (
          <>
            <div className="extranet-inventory__head--align-content">
              Discount
              <Tooltip
                title={<StaycationDiscountTooltip />}
                overlayClassName="extranet-inventory-tooltip"
                placement="topLeft"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <div></div>
          </>
        ) : (
          <>
            Discount
            <Tooltip
              title={<StaycationDiscountTooltip />}
              overlayClassName="extranet-inventory-tooltip"
              placement="topLeft"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </>
        )}
      </Col>
      {eachDayOfInterval(currentRange).map((date) => {
        const opening = room.openings.find(
          (o) => o.date === format(date, 'yyyy-MM-dd')
        );
        const dirtyOpening =
          dirtyOpenings.find(
            (o) =>
              o.date === format(date, 'yyyy-MM-dd') && o.roomId === room.room.id
          ) || opening;

        if (!opening || !dirtyOpening || !inventory) {
          return null;
        }

        const isRoomFocus =
          discountRoomFocus &&
          format(discountRoomFocus.date, 'yyyy-MM-dd') ===
            format(date, 'yyyy-MM-dd') &&
          discountRoomFocus.roomId === room.room.id
            ? true
            : false;

        return (
          <DiscountPrice
            key={`${date}-rate-row`}
            date={date}
            currentRange={currentRange}
            handlePropertyChange={handlePropertyChange}
            handleDuplicate={handleDuplicate}
            handleDuplicateHover={handleDuplicateHover}
            dropDuplicationHover={dropDuplicationHover}
            opening={opening}
            dirtyOpening={dirtyOpening}
            inventory={inventory}
            isEditing={isEditing}
            onFocus={() => onFocus(date, room.room.id)}
            isRoomFocus={isRoomFocus}
            onBlur={onBlur}
            autoDiscountRate={autoDiscountRate}
            lastMinuteAutoDiscountRate={lastMinuteAutoDiscountRate}
            lastMinuteAutoDiscountDays={lastMinuteAutoDiscountDays}
            hasChannelManager={hasChannelManager}
            stockScrapperEnabled={
              stockScrapperEnabled && !!room.room.bookingcomId
            }
          />
        );
      })}
    </Row>
  );
};

type DiscountPriceProps = Omit<
  Props,
  'room' | 'onFocus' | 'discountRoomFocus' | 'urlTriggerPricingRule'
> & {
  date: Date;
  opening: OpeningForm;
  dirtyOpening: OpeningForm;
  isEditing: boolean;
  inventory: Inventory;
  onFocus: () => void;
  isRoomFocus: boolean;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  lastMinuteAutoDiscountDays?: number;
  hasChannelManager: boolean;
  stockScrapperEnabled: boolean;
};

const DiscountPrice = ({
  currentRange,
  handlePropertyChange,
  handleDuplicate,
  handleDuplicateHover,
  dropDuplicationHover,
  opening,
  dirtyOpening,
  date,
  isEditing,
  inventory,
  onFocus,
  isRoomFocus,
  onBlur,
  autoDiscountRate,
  lastMinuteAutoDiscountRate,
  lastMinuteAutoDiscountDays,
  hasChannelManager,
  stockScrapperEnabled,
}: DiscountPriceProps) => {
  const minimalDiscount = inventory.pkg.dayPackage
    ? MINIMAL_DISCOUNT_DAY_PACKAGE
    : MINIMAL_DISCOUNT_STAYCATION;

  const isExternallyManaged =
    inventory.channelManager &&
    inventory.rateMode !== RateModes.SINGLE_RATE &&
    !autoDiscountRate;

  const getDiscountClassName = (
    discount: number | undefined,
    date: Date,
    opening: OpeningForm | undefined
  ) => {
    if (opening?.isForced) {
      return opening.published ? '' : 'red-text';
    }

    if (isPassed(date)) {
      return '';
    }

    if (
      discount &&
      (discount === Infinity ||
        discount > -1 ||
        discount > -minimalDiscount ||
        discount < -MAXIMUM_DISCOUNT)
    ) {
      return 'red-text';
    }

    return '';
  };

  const bookableDays = useAppSelector((s) =>
    s.hotelAdmin.inventory
      ? s.hotelAdmin.inventory.saleDate.bookableDays.concat(
          s.hotelAdmin.inventory.saleDate.extraDays
        )
      : []
  );

  const buildCommonClassname = (date: Date) =>
    [
      'extranet-inventory-automated-discount',
      'extranet-inventory__property-cell',
      'extranet-inventory__property-cell--large',
      isPassed(date) ? 'extranet-inventory__property-cell--passed' : '',
      isEndOfWeek(date) ? 'extranet-inventory__property-cell--end-of-week' : '',
      !isInDateArray(date, bookableDays) || isPassed(date)
        ? 'extranet-inventory__property-cell--inactive'
        : '',
    ].join(' ');
  const packageValue = computePackageValue(dirtyOpening, inventory, date);
  const discount = computeDiscount(dirtyOpening, packageValue);

  const tryDuplicate = (mode: DuplicationModeData, customDay?: Date) => {
    handleDuplicate('discountRate', discount || 0, date, mode, customDay);
  };

  const openingInLastMinuteAutoDiscountRange: boolean =
    !!lastMinuteAutoDiscountRate &&
    !!lastMinuteAutoDiscountDays &&
    !!opening.discountPrice &&
    isWithinInterval(new Date(opening.date), {
      start: new Date(),
      end: addDays(new Date(), lastMinuteAutoDiscountDays),
    });

  if (!isEditing) {
    const discountDisplay = (discount === Infinity ? 0 : discount) || 0;

    return (
      <Col
        className={buildCommonClassname(date)}
        flex="1"
        key={`${date}-${opening.roomId}-discount-rate`}
      >
        {!!opening && (
          <div className="property-cell--with-detail">
            <div
              className={[
                'property-cell--with-detail__content',
                getDiscountClassName(discount, date, opening),
              ].join(' ')}
            >
              {`${discountDisplay}%`}
            </div>
          </div>
        )}
        <DiscountIcon
          autoDiscountRate={autoDiscountRate}
          lastMinuteAutoDiscountRate={lastMinuteAutoDiscountRate}
          opening={opening}
          openingInLastMinuteAutoDiscountRange={
            openingInLastMinuteAutoDiscountRange
          }
          hasChannelManager={hasChannelManager}
          stockScrapperEnabled={stockScrapperEnabled}
        />
      </Col>
    );
  }

  const errors = dirtyOpening.errorStatus ?? [];
  const isRateError = errors.includes(OpeningErrors.EXCEED_RATE);
  const isMissingRateError = errors.includes(
    OpeningErrors.MISSING_DISCOUNT_PRICE
  );
  const isMinimumRateError = errors.includes(OpeningErrors.UNDER_MINIMUM_PRICE);
  const currentRateError =
    dirtyOpening.discountPrice &&
    dirtyOpening.price &&
    dirtyOpening.discountPrice >
      Math.round(dirtyOpening.price * (100 - minimalDiscount));
  const discountLabel = getDiscountLabel(
    discount,
    date,
    inventory.pkg.dayPackage || false
  );
  const openingHasError =
    !isPassed(date) &&
    ((isRateError && dirtyOpening && dirtyOpening.price) ||
      currentRateError ||
      isMissingRateError ||
      isMinimumRateError ||
      (discount !== undefined && discountLabel));

  const renderInput = () => {
    const value = -(discount || 0);

    const input = (
      <Input
        type="number"
        min={0}
        defaultValue={(dirtyOpening && dirtyOpening.discountPrice) || 0}
        disabled={isPassed(date) || isExternallyManaged}
        onChange={(e) => {
          const newDiscountRate = parseFloat(e.target.value);
          const newDiscountPrice =
            newDiscountRate === 0
              ? 0
              : packageValue - (packageValue * newDiscountRate) / 100;

          handlePropertyChange(dirtyOpening, 'discountPrice', newDiscountPrice);
        }}
        onBlurCapture={(e) => {
          const newDiscountRate = parseFloat(e.target.value);
          const newDiscountPrice =
            newDiscountRate === 0
              ? 0
              : Math.floor(
                  packageValue - (packageValue * newDiscountRate) / 100
                );

          if (newDiscountPrice !== dirtyOpening.discountPrice) {
            handlePropertyChange(
              dirtyOpening,
              'discountPrice',
              newDiscountPrice
            );
          }
        }}
        value={value}
        className={`property-cell--with-detail__input ${
          isRoomFocus ? 'property-cell--with-detail__input--focus' : ''
        }`}
        // Prevent the value to change when user scrolls.
        onWheel={(e) => e.currentTarget.blur()}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );

    if (isExternallyManaged) {
      return input;
    }

    return (
      <div className="extranet-inventory__property-cell--input">
        {input}
        <DuplicationMenu
          date={date}
          currentRange={currentRange}
          tryDuplicate={tryDuplicate}
          handleDuplicateHover={handleDuplicateHover}
          dropDuplicationHover={dropDuplicationHover}
        />
      </div>
    );
  };

  return (
    <Col
      className={[
        buildCommonClassname(date),
        openingHasError ? 'extranet-inventory__property-cell--error' : '',
      ].join(' ')}
      flex="1"
      key={`${date}-${opening ? opening.roomId : ''}-discount`}
    >
      <div className="property-cell--with-detail">
        {renderInput()}

        {discountLabel ? (
          <div
            className={[
              'property-cell--with-detail__detail',
              getDiscountClassName(discount, date, opening),
            ].join(' ')}
          >
            {discountLabel}
          </div>
        ) : null}
        <DiscountIcon
          autoDiscountRate={autoDiscountRate}
          opening={opening}
          lastMinuteAutoDiscountRate={lastMinuteAutoDiscountRate}
          openingInLastMinuteAutoDiscountRange={
            openingInLastMinuteAutoDiscountRange
          }
          hasChannelManager={hasChannelManager}
          stockScrapperEnabled={stockScrapperEnabled}
        />
      </div>
    </Col>
  );
};

type DiscountIconProps = {
  opening: OpeningForm;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  openingInLastMinuteAutoDiscountRange: boolean;
  hasChannelManager: boolean;
  stockScrapperEnabled: boolean;
};

const DiscountIcon = ({
  opening,
  autoDiscountRate,
  lastMinuteAutoDiscountRate,
  openingInLastMinuteAutoDiscountRange,
  hasChannelManager,
  stockScrapperEnabled,
}: DiscountIconProps) => {
  if (!hasChannelManager && !stockScrapperEnabled) {
    return <></>;
  }

  const colorClassName = hasChannelManager ? 'grey-icon' : 'blue-icon';
  let tootlipTitle;
  let icon;

  if (opening.lastMinuteAutoDiscount) {
    tootlipTitle = 'Last minute discount activated';
    icon = (
      <span className={colorClassName}>
        <DoubleThunderbolt />
      </span>
    );
  } else if (opening.autoDiscount) {
    tootlipTitle = 'Default discount activated';
    icon = <ThunderboltFilled className={colorClassName} />;
  } else if (
    (autoDiscountRate && opening.autoDiscount === false) ||
    (lastMinuteAutoDiscountRate &&
      opening.lastMinuteAutoDiscount === false &&
      openingInLastMinuteAutoDiscountRange)
  ) {
    tootlipTitle = 'Default discount deactivated by manual edition';
    icon = <ThunderboltOutlined className={colorClassName} />;
  } else {
    tootlipTitle = '';
    icon = <div style={{ width: '12px', height: '17px' }}></div>;
  }

  return (
    <div className="automated-discount__icon-container">
      <Tooltip placement="bottom" title={tootlipTitle} arrowPointAtCenter>
        {icon}
      </Tooltip>
    </div>
  );
};
